import m from "mithril";
import { accountState } from "./shared/account.js";
import { apiRequest } from "./shared/api-request.js";
import { classNames } from "./shared/util.js";
import { enforceLogin, updateHead } from "./util.js";

export const StatsActivityPage: m.ClosureComponent<{}> = () => {
  let statsResult: {
    eventRows: {
      user_id: number;
      event_name: string;
      timestamp: Date;
    }[];
    userRows: {
      username: string;
      user_id: number;
      email: string;
      creation_date: Date;
      subscription: string | null;
    }[];
  };

  return {
    oninit() {
      const getStats = async () => {
        const responseData = await apiRequest("getStats", {});
        if (responseData.success) {
          statsResult = responseData as any;
        }
      };
      getStats();
    },

    view(vnode) {
      if (!accountState.loggedInUser) {
        return enforceLogin();
      }

      updateHead({
        title: "Cuttle - Stats",
      });

      const timeFrameDates = previousDays(28);

      if (statsResult) {
        // Sort eventRows
        statsResult.eventRows.sort(
          (a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
        );

        let userEvents: {
          userId: number;
          email: string;
          creationDate: Date;
          username: string;
          subscription: string | null;
          events: {
            eventName: string;
            eventDate: Date;
          }[];
          eventsByDay: { [dateString: string]: number };
          timeSpentByDay: { [dateString: string]: number };
          timeSpentTotal: number;
        }[] = [];

        const pushUserRowData = statsResult.userRows.map((userRow) => {
          const userRowUserId = userRow.user_id;

          userEvents.push({
            userId: userRow.user_id,
            email: userRow.email,
            creationDate: new Date(userRow.creation_date),
            username: userRow.username,
            subscription: userRow.subscription,
            events: [],
            eventsByDay: {},
            timeSpentByDay: {}, // in minutes
            timeSpentTotal: 0,
          });
        });

        const pushEventRowData = statsResult.eventRows.map((eventRow) => {
          const eventDate = new Date(eventRow.timestamp);
          // If I don't care about the event, return
          const eventDateString = dateToString(eventDate);
          timeFrameDates.map((timeFrameDate) => {
            if (timeFrameDate.date !== eventDateString) {
              return;
            }
            for (let i = 0; i < userEvents.length; i++) {
              if (eventRow.user_id === userEvents[i].userId) {
                userEvents[i].events.push({
                  eventName: eventRow.event_name,
                  eventDate: eventDate,
                });
              }
            }
          });
        });

        const countEventsByDay = userEvents.map((userEvent) => {
          let previousTime = 0;
          let previousDateString = "";

          userEvent.events.map((event) => {
            const date = event.eventDate;
            const dateString = dateToString(date);
            const currentTime = new Date(date).getTime() / (1000 * 60);
            const timeBetweenEvents = currentTime - previousTime;

            // Time spent
            if (dateString === previousDateString) {
              if (timeBetweenEvents < 30) {
                userEvent.timeSpentByDay[dateString] += timeBetweenEvents;
                userEvent.timeSpentTotal += timeBetweenEvents;
              }
            } else {
              userEvent.timeSpentByDay[dateString] = 0;
            }
            previousTime = currentTime;
            previousDateString = dateString;

            // Autosaves
            if (userEvent.eventsByDay[dateString]) {
              userEvent.eventsByDay[dateString]++;
            } else {
              userEvent.eventsByDay[dateString] = 1;
            }
          });
        });

        userEvents.sort((a, b) => {
          return b.timeSpentTotal - a.timeSpentTotal;
        });

        const mTable = m("table", [
          m("tr", [
            m("th", "Username"),
            timeFrameDates.map((timeFrameDate) => {
              const dateString = timeFrameDate.date;
              let dayOfWeek = timeFrameDate.day;
              if (dayOfWeek === 0 || dayOfWeek === 6) {
                return m("th", dateString);
              } else {
                return m("th", dateString);
              }
            }),
            m("th", "Totals"),
          ]),
          userEvents.map((userEvent) => {
            if (Object.keys(userEvent.eventsByDay).length !== 0) {
              const admins = ["toby", "notlion", "mokey", "forresto", "cuttle"];
              const isAdmin = admins.includes(userEvent.username);
              const isPro = userEvent.subscription === "pro";
              const isK12 = userEvent.subscription === "K-12";
              const className = classNames({
                "stats-admin": isAdmin,
                "stats-pro": isPro,
                "stats-k12": isK12,
              });

              return m("tr", { className }, [
                // username
                m("td", [
                  //
                  m("div", userEvent.username),
                  m("div", [
                    m(
                      m.route.Link,
                      { href: "/stats/user?email=" + userEvent.email },
                      userEvent.email
                    ),
                  ]),
                  m("div", userEvent.subscription),
                ]),

                // entries
                timeFrameDates.map((timeFrameDate) => {
                  const dateString = timeFrameDate.date;
                  let dayOfWeek = timeFrameDate.day;
                  const minutes = userEvent.timeSpentByDay[dateString];
                  const className = dayOfWeek === 0 || dayOfWeek === 6 ? "weekend" : "";
                  return m("td", { className }, [
                    m("div", userEvent.eventsByDay[dateString]),
                    m("div", timeSpentInHoursAndMinutes(minutes)),
                  ]);
                }),

                // total
                m("td", [
                  m("div", userEvent.events.length),
                  m("div", timeSpentInHoursAndMinutes(userEvent.timeSpentTotal)),
                ]),
              ]);
            }
          }),
        ]);

        return m(".stats-page", [
          m(".stats-table", [
            m("h1", "🏆 Leaderboard 🏆"),
            m(
              "h2.stats-section-title",
              "Number of Autosaves / Time Spent from the Previous 4 Weeks"
            ),
            mTable,
          ]),
        ]);
      }
    },
  };
};

// Helpers

const dateToString = (date: Date) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return month + "/" + day;
};

const previousDays = (days: number) => {
  const date = new Date();
  let daysArray = [];
  for (let i = 0; i < days; i++) {
    daysArray.push({
      date: dateToString(date),
      day: date.getDay(),
    });
    date.setDate(date.getDate() - 1);
  }
  return daysArray.reverse();
};

const timeSpentInHoursAndMinutes = (minutes: number) => {
  const roundTime = Math.round(minutes);
  let hour;
  let min;
  if (!minutes) {
    return "";
  } else {
    hour = Math.floor(roundTime / 60);
    min = (roundTime % 60).toString();
    min = min.padStart(2, "0");
  }
  return `${hour}:${min}`;
};
