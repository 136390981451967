import m from "mithril";

import { SiteLayout } from "./shared/site-layout/site-layout";
import { Sidebar } from "./sidebar";
import { fetchText, markdownToHtml, updateHead } from "./util";

interface IntrinsicTypeData {
  type: "intrinsic";
  name: string;
}
interface LiteralTypeData {
  type: "literal";
  value: string;
}
interface ReferenceTypeData {
  type: "reference";
  name: string;
}
interface ArrayTypeData {
  type: "array";
  elementType: TypeData;
}
interface UnionTypeData {
  type: "union";
  types: TypeData[];
}
interface TupleTypeData {
  type: "tuple";
  elements: TypeData[];
}

type TypeData =
  | IntrinsicTypeData
  | LiteralTypeData
  | ReferenceTypeData
  | ArrayTypeData
  | UnionTypeData
  | TupleTypeData;

interface CommentTagReferenceData {
  tag: string;
  text: string;
}

interface CommentReferenceData {
  shortText?: string;
  text?: string;
  returns?: string;
  tags?: CommentTagReferenceData[];
}

interface ParameterReferenceData {
  name: string;
  type: TypeData;
  defaultValue?: string;
  comment?: CommentReferenceData;
  isOptional?: boolean;
}

interface FunctionReferenceData {
  name: string;
  comment?: CommentReferenceData;
  parameters: ParameterReferenceData[];
  returnType: TypeData;

  // The following only apply to class methods
  isStatic?: boolean;
  inheritedFrom?: string;
}

interface VariableReferenceData {
  name: string;
  type: TypeData;
  comment?: CommentReferenceData;
  isOptional?: boolean;
}

interface InterfaceReferenceData {
  name: string;
  comment: CommentReferenceData;
  properties: VariableReferenceData[];
  methods: FunctionReferenceData[];
}

interface ClassReferenceData {
  name: string;
  comment: CommentReferenceData;
  constructors: FunctionReferenceData[];
  factories: FunctionReferenceData[];
  properties: VariableReferenceData[];
  methods: FunctionReferenceData[];
  extended?: { [className: string]: { methods: FunctionReferenceData[] } };
  isAbstract?: boolean;
}

interface ReferencePage {
  title: string;
  content: string;
}
export const referencePages: { [path: string]: ReferencePage } = {
  "/math": {
    title: "Math",
    content: "/content/api/math.json",
  },
  "/utilities": {
    title: "Utilities",
    content: "/content/api/util.json",
  },
  //
  "/Containment": {
    title: "Containment",
    content: "/content/api/Containment.json",
  },
  "/PathContainment": {
    title: "PathContainment",
    content: "/content/api/PathContainment.json",
  },
  //
  "/interfaces": {
    title: "Interfaces",
    content: "/content/api/interfaces.json",
  },
  //
  "/text": {
    title: "Text",
    content: "/content/api/text.json",
  },
  "/Font": {
    title: "Font",
    content: "/content/api/Font.json",
  },
  "/RichText": {
    title: "RichText",
    content: "/content/api/RichText.json",
  },
  "/RichTextGlyph": {
    title: "RichTextGlyph",
    content: "/content/api/RichTextGlyph.json",
  },
  "/RichTextSymbol": {
    title: "RichTextSymbol",
    content: "/content/api/RichTextSymbol.json",
  },
  //
  "/random": {
    title: "Random",
    content: "/content/api/random.json",
  },
  "/RandomGenerator": {
    title: "RandomGenerator",
    content: "/content/api/RandomGenerator.json",
  },
  //
  "/Vec": {
    title: "Vec",
    content: "/content/api/Vec.json",
  },
  "/AffineMatrix": {
    title: "AffineMatrix",
    content: "/content/api/AffineMatrix.json",
  },
  "/BoundingBox": {
    title: "BoundingBox",
    content: "/content/api/BoundingBox.json",
  },
  //
  "/Geometry": {
    title: "Geometry",
    content: "/content/api/Geometry.json",
  },
  "/Axis": {
    title: "Axis",
    content: "/content/api/Axis.json",
  },
  "/Ray": {
    title: "Ray",
    content: "/content/api/Ray.json",
  },
  "/LineSegment": {
    title: "LineSegment",
    content: "/content/api/LineSegment.json",
  },
  "/CubicSegment": {
    title: "CubicSegment",
    content: "/content/api/CubicSegment.json",
  },
  //
  "/Graphic": {
    title: "Graphic",
    content: "/content/api/Graphic.json",
  },
  "/Anchor": {
    title: "Anchor",
    content: "/content/api/Anchor.json",
  },
  "/Path": {
    title: "Path",
    content: "/content/api/Path.json",
  },
  "/CompoundPath": {
    title: "CompoundPath",
    content: "/content/api/CompoundPath.json",
  },
  "/Group": {
    title: "Group",
    content: "/content/api/Group.json",
  },
  "/Color": {
    title: "Color",
    content: "/content/api/Color.json",
  },
  "/Fill": {
    title: "Fill",
    content: "/content/api/Fill.json",
  },
  "/ImageFill": {
    title: "ImageFill",
    content: "/content/api/ImageFill.json",
  },
  "/Stroke": {
    title: "Stroke",
    content: "/content/api/Stroke.json",
  },
};

const customTypeURLs: { [name: string]: string } = {
  ClosestPointResult: "/learn/reference/interfaces#ClosestPointResult",
  TransformArgs: "/learn/reference/interfaces#TransformArgs",
  Transform: "/learn/reference/interfaces#Transform",
  TransformWithOrigin: "/learn/reference/interfaces#TransformWithOrigin",
  IntersectionPrimitive: "/learn/reference/interfaces#IntersectionPrimitive",
  IntersectionResult: "/learn/reference/interfaces#IntersectionResult",
  FontRenderOptions: "/learn/reference/interfaces#FontRenderOptions",
  RenderLineResult: "/learn/reference/interfaces#RenderLineResult",
  AlternateGlyphReplacements: "/learn/reference/interfaces#AlternateGlyphReplacements",
  GlyphAlternateInfo: "/learn/reference/interfaces#GlyphAlternateInfo",
  GlyphRangeAlternateIndex: "/learn/reference/interfaces#GlyphRangeAlternateIndex",
  GlyphRangeIndices: "/learn/reference/interfaces#GlyphRangeIndices",
  GeometryPrimitive: "/learn/reference/interfaces#GeometryPrimitive",
  OverlapSpan: "/learn/reference/interfaces#OverlapSpan",
};

interface ReferencePageAttrs {
  page: ReferencePage;
}
export const ReferencePage: m.Component<ReferencePageAttrs> = {
  view({ attrs: { page } }) {
    updateHead({
      title: "Cuttle - " + page.title,
    });

    let mContent: m.Children;

    const contentJsonString = fetchText(page.content);
    if (contentJsonString === undefined) {
      // Loading
    } else {
      const ref = JSON.parse(contentJsonString);
      if (ref.kind === "class") {
        mContent = m(ClassReference, { ref });
      } else if (ref.interfaces) {
        mContent = [
          m(
            "p",
            "Interfaces describe the shape of JavaScript objects that are accepted as arguments to functions, or are returned as results. The names of these interfaces can't be referred to in code. They exist here for documentation purposes."
          ),
          ref.interfaces.map((ref: any) => m(InterfaceReference, { ref })),
        ];
      } else {
        let mFunctions: m.Children;
        if (ref.functions.length > 0) {
          mFunctions = [
            m("h1", "Functions"),
            ref.functions.map((ref: any) => m(FunctionReference, { ref })),
          ];
        }

        let mConstants: m.Children;
        if (ref.constants.length > 0) {
          mConstants = [
            m("h1", "Constants"),
            ref.constants.map((ref: any) => m(VariableReference, { ref })),
          ];
        }

        mContent = [mFunctions, mConstants];
      }
    }

    return m(SiteLayout, { hamburgerExtra: () => m(Sidebar) }, [
      m(".white-background-when-small", [
        m(".main-inner", [
          m(".with-sidebar", [
            m(Sidebar),
            m("article", [
              //
              m("h1.title", page.title),
              m(".reference-content", mContent),
            ]),
          ]),
        ]),
      ]),
    ]);
  },
  oncreate() {
    scrollToHash();
  },
  onupdate() {
    scrollToHash();
  },
};

/**
 * The Mithril router scrolls to the top of the page on every route change. Hack
 * so that links like this will work as expected:
 * https://cuttle.xyz/learn/reference/interfaces#TransformArgs
 */
function scrollToHash() {
  if (window.location.hash) {
    const element = document.getElementById(window.location.hash.slice(1));
    if (element) {
      element.scrollIntoView();
    }
  }
}

interface ClassReferencePageAttrs {
  ref: ClassReferenceData;
}
export const ClassReference: m.Component<ClassReferencePageAttrs> = {
  view({ attrs: { ref } }) {
    const staticPrefix = ref.name + ".";

    let mProperties: m.Children;
    if (ref.properties.length > 0) {
      mProperties = m(".properties", [
        m("h2", "Properties"),
        ref.properties.map((property) =>
          m(VariableReference, { ref: property, displayName: "." + property.name })
        ),
      ]);
    }

    let mConstructors: m.Children;
    if (!ref.isAbstract) {
      mConstructors = m(".constructors", [
        m("h2", "Constructors"),
        ref.constructors.map((functionRef: any) =>
          m(FunctionReference, { ref: functionRef, displayName: ref.name })
        ),
        ref.factories.map((functionRef: any) =>
          m(FunctionReference, { ref: functionRef, displayName: staticPrefix + functionRef.name })
        ),
      ]);
    } else {
      mConstructors = mConstructors = m(".constructors", [
        m("h2", "Constructors"),
        m(
          "p.abstract-warning",
          `${ref.name} is an abstract type and cannot be instantiated its own.`
        ),
      ]);
    }

    let mMethods: m.Children;
    if (ref.methods.length > 0) {
      mMethods = m(".methods", [
        m("h2", "Methods"),
        ref.methods.map((functionRef: any) => {
          const prefix = functionRef.isStatic ? staticPrefix : ".";
          return m(FunctionReference, { ref: functionRef, displayName: prefix + functionRef.name });
        }),
      ]);
    }

    let mInherited: m.Children;
    if (ref.extended) {
      mInherited = Object.entries(ref.extended).map(([extendedClassName, extendedClassRef]) => [
        m(
          "h2",
          "Methods inherited from ",
          m(
            "span.type",
            m(m.route.Link, { href: `/learn/reference/${extendedClassName}` }, extendedClassName)
          )
        ),
        extendedClassRef.methods.map((functionRef: any) => {
          return m(FunctionReference, { ref: functionRef, displayName: "." + functionRef.name });
        }),
      ]);
    }

    return m(".class-reference", [
      m(Comment, { comment: ref.comment }),
      mConstructors,
      mProperties,
      mMethods,
      mInherited,
    ]);
  },
};

interface InterfaceReferenceAttrs {
  ref: InterfaceReferenceData;
}
const InterfaceReference: m.Component<InterfaceReferenceAttrs> = {
  view({ attrs: { ref } }) {
    let mProperties: m.Children;
    if (ref.properties.length > 0) {
      mProperties = m(".properties", [
        m("h2", "Properties"),
        ref.properties.map((property) =>
          m(VariableReference, { ref: property, displayName: "." + property.name })
        ),
      ]);
    }

    let mMethods: m.Children;
    if (ref.methods.length > 0) {
      mMethods = m(".methods", [
        m("h2", "Methods"),
        ref.methods.map((functionRef: any) => {
          return m(FunctionReference, { ref: functionRef, displayName: "." + functionRef.name });
        }),
      ]);
    }

    return m(".interface-reference", [
      m("h1.name", { id: ref.name }, ref.name),
      m(Comment, { comment: ref.comment }),
      mProperties,
      mMethods,
    ]);
  },
};

interface CommentAttrs {
  comment: CommentReferenceData | undefined;
}
const Comment: m.Component<CommentAttrs> = {
  view({ attrs: { comment } }) {
    if (!comment) return;
    const mChildren: m.Child[] = [];
    if (comment.shortText) {
      const html = markdownToHtml(comment.shortText);
      mChildren.push(m.trust(html));
    }
    if (comment.text) {
      const html = markdownToHtml(comment.text);
      mChildren.push(m.trust(html));
    }
    if (mChildren.length === 0) return undefined;
    return m(".comment", mChildren);
  },
};

interface VariableReferenceAttrs {
  ref: VariableReferenceData;
  displayName?: string;
}
const VariableReference: m.Component<VariableReferenceAttrs> = {
  view({ attrs: { ref, displayName } }) {
    if (!displayName) {
      displayName = ref.name;
    }
    if (ref.isOptional) {
      displayName += "?";
    }

    return m(".variable-reference", [
      m(".variable-signature", [
        m("span.variable-name", displayName),
        m("span.variable-type", m(TypeReference, { type: ref.type })),
        ref.isOptional && m("span.tag", "optional"),
      ]),
      m(".variable-comment", m(Comment, { comment: ref.comment })),
    ]);
  },
};

interface FunctionReferenceAttrs {
  ref: FunctionReferenceData;
  displayName?: string;
}
const FunctionReference: m.Component<FunctionReferenceAttrs> = {
  view({ attrs: { ref, displayName } }) {
    let mReturnType: m.Children;
    if (ref.returnType) {
      mReturnType = [m("span.return-arrow", "→"), m(TypeReference, { type: ref.returnType })];
    }

    let mReturns: m.Children;
    if (ref.comment?.returns) {
      const html = markdownToHtml(`Returns ${ref.comment.returns.trim()}`);
      mReturns = m(".function-returns.comment", m.trust(html));
    }

    let mParameters: m.Children;
    if (ref.parameters.length > 0) {
      mParameters = m(".function-parameters", [
        ref.parameters.map((parameter) => {
          let parameterName = parameter.name;
          if (parameter.isOptional) {
            parameterName += "?";
          }

          let mDefault: m.Child;
          if (parameter.defaultValue) {
            mDefault = m(".function-parameter-default", m("code", parameter.defaultValue));
          } else if (parameter.isOptional) {
            mDefault = m("span.tag", "optional");
          }

          let mComment: m.Child;
          if (parameter.comment) {
            mComment = m(".function-parameter-comment", m(Comment, { comment: parameter.comment }));
          }

          return [
            m(".function-parameter-name", m("code", parameterName)),
            m(".function-parameter-type", m("code", m(TypeReference, { type: parameter.type }))),
            mDefault,
            mComment,
          ];
        }),
      ]);
    }

    if (!displayName) {
      displayName = ref.name;
    }

    return m(".function-reference", [
      m(".function-signature", [
        m("span.function-signature-name", displayName),
        "(",
        ref.parameters
          .flatMap((parameter) => {
            let parameterName = parameter.name;
            if (parameter.isOptional) {
              parameterName += "?";
            }
            return [m("span.parameter-name", parameterName), m("span.comma", ",")];
          })
          .slice(0, -1),
        ")",
        m("span.function-signature-return", mReturnType),
        ref.comment?.tags?.map((tag) => m("span.tag", tag.tag)),
      ]),
      m(Comment, { comment: ref.comment }),
      mParameters,
      mReturns,
      // mInherited,
    ]);
  },
};

interface TypeLinkAttrs {
  name: string;
}
const TypeLink: m.Component<TypeLinkAttrs> = {
  view({ attrs: { name } }) {
    let href = customTypeURLs[name];
    if (!href) {
      href = `/learn/reference/${name}`;
    }
    return m(m.route.Link, { href }, name);
  },
};

interface TypeReferenceAttrs {
  type: TypeData;
}
const TypeReference: m.Component<TypeReferenceAttrs> = {
  view({ attrs: { type } }) {
    if (type.type === "intrinsic") {
      return m("span.type", type.name);
    }
    if (type.type === "literal") {
      return m("span.type", `"${type.value}"`);
    }
    if (type.type === "reference") {
      return m("span.type", m(TypeLink, { name: type.name }));
    }
    if (type.type === "array") {
      return m("span.type", m(TypeReference, { type: type.elementType }), "[]");
    }
    if (type.type === "tuple") {
      return m("span.type", [
        "[",
        type.elements.flatMap((type) => [m(TypeReference, { type }), ", "]).slice(0, -1),
        "]",
      ]);
    }
    if (type.type === "union") {
      return m("span.type", [
        "(",
        type.types.flatMap((type) => [m(TypeReference, { type }), " | "]).slice(0, -1),
        ")",
      ]);
    }
  },
};
