import m from "mithril";

import { AvatarUploadButton } from "./avatar-modal";
import { accountState } from "./shared/account";
import { apiRequest, apiRequestSuccess } from "./shared/api-request";
import { AvatarImage } from "./shared/avatar-image";
import { logEvent } from "./shared/log-event";
import { SiteLayout } from "./shared/site-layout/site-layout";
import { enforceLogin, updateHead } from "./util";

export const redirectToCustomerPortalSessionUrl = async () => {
  const { customerPortalSessionUrl } = await apiRequestSuccess("createCustomerPortalSession", {});
  window.location.href = customerPortalSessionUrl;
};

export const SettingsPage: m.ClosureComponent<{}> = () => {
  let typedDisplayName = accountState.loggedInUser?.displayName;
  let displayNameTooLong = false;
  let typedUsername = accountState.loggedInUser?.username;
  let usernameValid = true;
  let usernameExists = false;
  let typedEmail = accountState.loggedInUser?.email;
  let emailValid = true;
  let emailExists = false;
  let typedOldPassword = "";
  let typedNewPassword = "";
  let oldPasswordCorrect = true;
  let passwordHasBeenChanged = false;

  return {
    view(vnode) {
      if (!accountState.loggedInUser) {
        return enforceLogin();
      }

      updateHead({
        title: "Cuttle - Settings",
      });

      const changeDisplayName = async (event: Event) => {
        event.preventDefault();

        displayNameTooLong = false;

        const el: HTMLElement = (vnode as any).dom;

        const displayName = (el.querySelector(".set-display-name") as HTMLInputElement).value;

        const responseData = await apiRequest("setDisplayName", {
          displayName,
        });

        if (responseData.success) {
          await accountState.refreshLoggedInUser();
        } else {
          displayNameTooLong = true;
        }
      };

      let mDisplayNameError;

      if (typedDisplayName && typedDisplayName.length > 30) {
        mDisplayNameError = m("p.settings-error", "Display name must be less than 30 characters");
      }

      const changeUsername = async (event: Event) => {
        event.preventDefault();

        usernameValid = true;
        usernameExists = false;

        const el: HTMLElement = (vnode as any).dom;

        const username = (el.querySelector(".change-username") as HTMLInputElement).value;

        const responseData = await apiRequest("changeUsername", {
          username,
        });

        if (responseData.success) {
          await accountState.refreshLoggedInUser();
        } else {
          if (responseData.message === "Username not available") {
            usernameExists = true;
          }
          if (responseData.message === "Username is invalid") {
            usernameValid = false;
          }
        }
      };

      let mUsernameError;

      if (!usernameValid) {
        mUsernameError = m(
          "p.settings-error",
          "Username must be less than 15 characters and can only contain letters, numbers and '_'"
        );
      }

      if (usernameExists) {
        mUsernameError = m("p.settings-error", "Username not available");
      }

      const changeEmail = async (event: Event) => {
        event.preventDefault();

        emailValid = true;
        emailExists = false;

        const el: HTMLElement = (vnode as any).dom;

        const email = (el.querySelector(".change-email") as HTMLInputElement).value;

        const responseData = await apiRequest("changeEmail", {
          email,
        });

        if (responseData.success) {
          await accountState.refreshLoggedInUser();
        } else {
          if (responseData.message === "Email not available") {
            emailExists = true;
          }
          if (responseData.message === "Email is invalid") {
            emailValid = false;
          }
        }
      };

      let mEmailError;

      if (emailExists) {
        mEmailError = m("p.settings-error", "Email not available");
      }

      if (!emailValid) {
        mEmailError = m("p.settings-error", "Email is invalid");
      }

      const changePassword = async (event: Event) => {
        event.preventDefault();

        oldPasswordCorrect = true;
        passwordHasBeenChanged = false;

        const el: HTMLElement = (vnode as any).dom;

        const oldPassword = (el.querySelector(".old-password") as HTMLInputElement).value;
        const newPassword = (el.querySelector(".new-password") as HTMLInputElement).value;

        const responseData = await apiRequest("changePassword", {
          oldPassword,
          newPassword,
        });

        if (responseData.success) {
          passwordHasBeenChanged = true;
          (el.querySelector(".old-password") as HTMLInputElement).value = "";
          (el.querySelector(".new-password") as HTMLInputElement).value = "";
        } else {
          oldPasswordCorrect = false;
        }
      };

      let mPasswordError;
      if (!oldPasswordCorrect) {
        mPasswordError = m("p.settings-error", "Old password is incorrect");
      }

      let mPasswordMessage;
      if (passwordHasBeenChanged) {
        mPasswordMessage = m("p.settings-message", "Success! Your password has been changed.");
      }

      let mOldPasswordInput = m("input.old-password", {
        type: "password",
        id: "old-password",
        oninput: (event: InputEvent) => {
          typedOldPassword = (event.target as HTMLInputElement).value;
          if (!typedOldPassword) {
            oldPasswordCorrect = true;
          }
        },
      });

      let mNewPasswordInput = m("input.new-password", {
        type: "password",
        id: "new-password",
        oninput: (event: InputEvent) => {
          typedNewPassword = (event.target as HTMLInputElement).value;
        },
      });

      return m(SiteLayout, [
        m(".settings-container", [
          m("h1.title", "Account Settings"),
          m(".settings-account-message", "Signed in as ", [
            m("span", `${accountState.loggedInUser.email}`),
            m("a", { href: "/logout" }, "Log Out"),
          ]),
          m(SubscriptionSection),

          // TODO: For better organization, each section here could be its own
          // component.

          m("h3", "Profile Picture"),
          m(".avatar-settings-container", [
            m(AvatarImage, {
              avatarUrl: accountState.loggedInUser!.avatar,
              username: accountState.loggedInUser!.username,
              size: 250,
            }),
            m(AvatarUploadButton),
          ]),
          m("h3", "Name"),
          m("form.settings-form", { onsubmit: changeDisplayName }, [
            m(".settings-input-and-button", [
              m("input.set-display-name", {
                type: "text",
                value: typedDisplayName,
                oninput: (event: InputEvent) => {
                  typedDisplayName = (event.target as HTMLInputElement).value;
                },
              }),
              m(
                "button.settings",
                {
                  disabled:
                    typedDisplayName === accountState.loggedInUser?.displayName ||
                    typedDisplayName === "",
                },
                "Update Name"
              ),
            ]),
            mDisplayNameError,
          ]),
          m("h3", "Username"),
          m("form.settings-form", { onsubmit: changeUsername }, [
            m(".settings-input-and-button", [
              m("input.change-username", {
                type: "text",
                value: typedUsername,
                oninput: (event: InputEvent) => {
                  typedUsername = (event.target as HTMLInputElement).value;
                  if (!typedUsername || typedUsername === accountState.loggedInUser?.username) {
                    usernameValid = true;
                    usernameExists = false;
                  }
                },
              }),
              m(
                "button.settings",
                {
                  disabled:
                    typedUsername === accountState.loggedInUser?.username || typedUsername === "",
                },
                "Update Username"
              ),
            ]),
            mUsernameError,
          ]),
          m("h3", "Email"),
          m("form.settings-form", { onsubmit: changeEmail }, [
            m(".settings-input-and-button", [
              m("input.change-email", {
                type: "email",
                value: typedEmail,
                oninput: (event: InputEvent) => {
                  typedEmail = (event.target as HTMLInputElement).value;
                  if (!typedEmail) {
                    emailValid = true;
                    emailExists = false;
                  }
                },
              }),
              m(
                "button.settings",
                {
                  disabled: typedEmail === accountState.loggedInUser?.email || typedEmail === "",
                },
                "Update Email"
              ),
            ]),
            mEmailError,
          ]),
          m("h3", "Password"),
          m("form.settings-form", { onsubmit: changePassword }, [
            m("label.label", { for: "old-password" }, "Old Password"),
            m(".settings-input-and-button", [mOldPasswordInput]),
            m("label.label", { for: "new-password" }, "New Password"),
            m(".settings-input-and-button", [
              mNewPasswordInput,
              m(
                "button.settings",
                {
                  disabled:
                    typedOldPassword === "" || typedNewPassword === "" || passwordHasBeenChanged,
                },
                "Update Password"
              ),
            ]),
            mPasswordError,
            mPasswordMessage,
          ]),
        ]),
      ]);
    },
  };
};

const UpgradeSubscriptionButton: m.Component<{}> = {
  view() {
    return m("button.settings", [
      m(
        m.route.Link,
        { href: "/pricing", onclick: () => logEvent("clicked pricing", { from: "settings" }) },
        "Upgrade Your Account"
      ),
    ]);
  },
};

const ManageSubscriptionButton: m.Component<{}> = {
  view() {
    return m(
      "button.settings",
      { onclick: redirectToCustomerPortalSessionUrl },
      "Manage Your Subscription"
    );
  },
};

const SubscriptionSection: m.Component<{}> = {
  view() {
    const subscription = accountState.loggedInUser!.subscription;

    let mSubscriptionExtra;
    let projectOrProjects = "projects";

    if (subscription.plan === "Free") {
      if (subscription.projectsRemaining === 1) {
        projectOrProjects = "project";
      }
      mSubscriptionExtra = [
        m("p", [
          "You have ",
          m("strong", subscription.projectsRemaining),
          ` free ${projectOrProjects} remaining.`,
        ]),
        m(UpgradeSubscriptionButton),
      ];
    } else if (subscription.plan === "K-12 Education") {
      mSubscriptionExtra = [
        m("p", [
          "Email ",
          m("a", { href: "mailto:education@cuttle.xyz" }, "education@cuttle.xyz"),
          " to make changes to your account.",
        ]),
      ];
    } else if (subscription.plan === "Pro") {
      const currentPeriodEndDate = new Date(subscription.currentPeriodEnd);

      const longEnUSFormatter = new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });

      const expirationDate = longEnUSFormatter.format(currentPeriodEndDate);

      if (subscription.status === "canceled") {
        mSubscriptionExtra = [
          m(
            "p",
            "Your subscription has been canceled. Your account will downgrade to the Free plan at the end of your billing period."
          ),
          m(ManageSubscriptionButton),
        ];
      } else if (subscription.status === "one-time") {
        mSubscriptionExtra = m("p", [
          "Your ",
          m("strong", "One Week Pass"),
          " will expire on ",
          m("strong", expirationDate),
          ".",
        ]);
      } else {
        mSubscriptionExtra = [m(ManageSubscriptionButton)];
      }
    }

    return [
      m("h3", "Subscription"),
      m(".settings-form", [
        m("p", ["You are currently on the ", m("strong", subscription.plan), " plan."]),
        mSubscriptionExtra,
      ]),
    ];
  },
};
