import m from "mithril";
import { blogOrigin } from "./shared/config";
import { navState } from "./shared/site-layout/nav-state";
import { classNames } from "./shared/util";
import { Icon20 } from "./shared/icon";

export const expandSidebarSectionBasedOnUrl = () => {
  const pathname = window.location.pathname;
  if (pathname.startsWith("/learn/basics/")) {
    navState.sidebarExpandedSections["Basics"] = true;
  } else if (pathname.startsWith("/learn/common-questions/")) {
    navState.sidebarExpandedSections["Common Questions"] = true;
  } else if (pathname.startsWith("/learn/parameters/")) {
    navState.sidebarExpandedSections["Parameters"] = true;
  } else if (pathname.startsWith("/learn/scripting/")) {
    navState.sidebarExpandedSections["Scripting"] = true;
  } else if (pathname.startsWith("/learn/reference/")) {
    navState.sidebarExpandedSections["Scripting Reference"] = true;
  } else if (pathname.startsWith("/templates")) {
    navState.sidebarExpandedSections["Templates"] = true;
  } else if (pathname.startsWith("/dashboard/")) {
    navState.sidebarExpandedSections["My Projects"] = true;
  }
};

interface SidebarLinkAttrs {
  url: string;
  title: string;
  external?: boolean;
  isTopLevel?: boolean;
}

export const SidebarLink: m.Component<SidebarLinkAttrs> = {
  view({ attrs: { url, title, external, isTopLevel } }) {
    const className = classNames({
      "sidebar-item": true,
      "is-top-level": isTopLevel,
      "is-highlighted": window.location.pathname === url,
    });

    if (external) {
      return m("a", { className, href: url }, title);
    } else {
      return m(m.route.Link, { className, href: url }, title);
    }
  },
};

interface SidebarSectionAttrs {
  title: string;
}

export const SidebarSection: m.Component<SidebarSectionAttrs> = {
  view(vnode) {
    const title = vnode.attrs.title;

    const className = classNames({
      "is-open": navState.sidebarExpandedSections[title],
    });

    let mContents;

    if (navState.sidebarExpandedSections[title] === true) {
      mContents = m(".sidebar-section-contents", [vnode.children]);
    }

    return m(".sidebar-section", [
      m(
        ".sidebar-item.is-top-level",
        {
          onclick: () =>
            (navState.sidebarExpandedSections[title] = !navState.sidebarExpandedSections[title]),
        },
        [
          //
          m("div", title),
          m(".sidebar-expander", { className }, [m(Icon20, { icon: "chevron_right" })]),
          // m(".expander-triangle", { className }),
        ]
      ),
      mContents,
    ]);
  },
};

export const Sidebar: m.Component<{}> = {
  view(vnode) {
    return m(".sidebar", [
      m(".sidebar-section", [
        m(SidebarLink, {
          url: "/learn/video-tutorials",
          title: "Video Tutorials",
          isTopLevel: true,
        }),
      ]),
      m(SidebarSection, { title: "Basics" }, [
        m(SidebarLink, { url: "/learn/basics/getting-started", title: "Getting Started" }),
        m(SidebarLink, {
          url: "/learn/basics/understanding-the-cuttle-interface",
          title: "Understanding the Cuttle Interface",
        }),
        m(SidebarLink, {
          url: "/learn/basics/pen-tool-and-path-editing",
          title: "Pen Tool and Path Editing",
        }),
        m(SidebarLink, {
          url: "/learn/basics/guides",
          title: "Guides",
        }),
      ]),
      m(SidebarSection, { title: "Common Questions" }, [
        m(SidebarLink, {
          url: "/learn/common-questions/licensing",
          title: "Licensing",
        }),
        m(SidebarLink, {
          url: "/learn/common-questions/adding-your-own-fonts",
          title: "Adding Your Own Fonts",
        }),
        m(SidebarLink, {
          url: "/learn/common-questions/how-to-score-on-a-laser-cutter",
          title: 'How to "Score" on a Laser Cutter',
        }),
        m(SidebarLink, {
          url: "/learn/common-questions/changing-units",
          title: "Changing Units",
        }),
        m(SidebarLink, {
          url: "/learn/common-questions/copying-components-from-one-project-to-another",
          title: "Copying Components",
        }),
        m(SidebarLink, {
          url: "/learn/common-questions/cuttle-system-requirements",
          title: "Cuttle System Requirements",
        }),
      ]),
      m(SidebarSection, { title: "Parameters" }, [
        m(SidebarLink, {
          url: "/learn/parameters/getting-started-with-parameters",
          title: "Getting Started with Parameters",
        }),
        m(SidebarLink, {
          url: "/learn/parameters/math-reference",
          title: "Math Reference",
        }),
        m(SidebarLink, {
          url: "/learn/parameters/vector-reference",
          title: "Vector Reference",
        }),
      ]),
      m(SidebarSection, { title: "Repetitions" }, [
        m(SidebarLink, {
          url: "/learn/repetitions/customized-repetitions",
          title: "Customized Repetitions",
        }),
      ]),
      m(SidebarSection, { title: "Scripting" }, [
        m(SidebarLink, {
          url: "/learn/scripting/getting-started-with-scripting",
          title: "Getting Started with Scripting",
        }),
        m(SidebarLink, {
          url: "/learn/scripting/code-components",
          title: "Code Components",
        }),
        m(SidebarLink, {
          url: "/learn/scripting/scripting-fundamentals",
          title: "Scripting Fundamentals",
        }),
        m(SidebarLink, {
          url: "/learn/scripting/calling-other-components-and-modifiers",
          title: "Calling Other Components and Modifiers",
        }),
        m(SidebarLink, {
          url: "/learn/scripting/working-with-paths",
          title: "Working with Paths",
        }),
      ]),
      m(SidebarSection, { title: "Scripting Reference" }, [
        m(SidebarLink, {
          url: "/learn/reference/math",
          title: "Math",
        }),
        m(".sidebar-indent", [
          m(SidebarLink, {
            url: "/learn/reference/Vec",
            title: "Vec",
          }),
          m(SidebarLink, {
            url: "/learn/reference/AffineMatrix",
            title: "AffineMatrix",
          }),
          m(SidebarLink, {
            url: "/learn/reference/BoundingBox",
            title: "BoundingBox",
          }),
        ]),
        m(SidebarLink, {
          url: "/learn/reference/Geometry",
          title: "Geometry",
        }),
        m(".sidebar-indent", [
          m(SidebarLink, {
            url: "/learn/reference/Axis",
            title: "Axis",
          }),
          m(SidebarLink, {
            url: "/learn/reference/Ray",
            title: "Ray",
          }),
          m(SidebarLink, {
            url: "/learn/reference/LineSegment",
            title: "LineSegment",
          }),
          m(SidebarLink, {
            url: "/learn/reference/CubicSegment",
            title: "CubicSegment",
          }),
        ]),
        m(SidebarLink, {
          url: "/learn/reference/Graphic",
          title: "Graphic",
        }),
        m(".sidebar-indent", [
          m(SidebarLink, {
            url: "/learn/reference/Anchor",
            title: "Anchor",
          }),
          m(SidebarLink, {
            url: "/learn/reference/Path",
            title: "Path",
          }),
          m(SidebarLink, {
            url: "/learn/reference/CompoundPath",
            title: "CompoundPath",
          }),
          m(SidebarLink, {
            url: "/learn/reference/Group",
            title: "Group",
          }),
          m(SidebarLink, {
            url: "/learn/reference/Color",
            title: "Color",
          }),
          m(SidebarLink, {
            url: "/learn/reference/Fill",
            title: "Fill",
          }),
          m(SidebarLink, {
            url: "/learn/reference/ImageFill",
            title: "ImageFill",
          }),
          m(SidebarLink, {
            url: "/learn/reference/Stroke",
            title: "Stroke",
          }),
        ]),
        m(SidebarLink, {
          url: "/learn/reference/Font",
          title: "Font",
        }),
        m(SidebarLink, {
          url: "/learn/reference/RichText",
          title: "RichText",
        }),
        m(".sidebar-indent", [
          // TODO: Uncomment this when we release Font alternate glyph replacements.
          // m(SidebarLink, {
          //   url: "/learn/reference/RichTextGlyph",
          //   title: "RichTextGlyph",
          // }),
          m(SidebarLink, {
            url: "/learn/reference/RichTextSymbol",
            title: "RichTextSymbol",
          }),
        ]),
        m(SidebarLink, {
          url: "/learn/reference/random",
          title: "Random",
        }),
        m(".sidebar-indent", [
          m(SidebarLink, {
            url: "/learn/reference/RandomGenerator",
            title: "RandomGenerator",
          }),
        ]),
        m(SidebarLink, {
          url: "/learn/reference/utilities",
          title: "Utilities",
        }),
        m(".sidebar-indent", [
          m(SidebarLink, {
            url: "/learn/reference/Containment",
            title: "Containment",
          }),
          m(SidebarLink, {
            url: "/learn/reference/PathContainment",
            title: "PathContainment",
          }),
        ]),
        m(SidebarLink, {
          url: "/learn/reference/interfaces",
          title: "Interfaces",
        }),
      ]),
      m(".sidebar-section", [
        m(SidebarLink, {
          url: blogOrigin + "/tag/whats-new/",
          title: "What's New",
          external: true,
          isTopLevel: true,
        }),
      ]),
    ]);
  },
};
