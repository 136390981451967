import { base64StringToBlob } from "blob-util";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import m from "mithril";
import SuperExpressive from "super-expressive";

import { publicBucketName } from "./config";

// set up dayjs with the utc plugin
dayjs.extend(utc);

export const isNumber = (value: unknown): value is number => {
  return typeof value === "number" && !isNaN(value);
};
export const isString = (value: unknown): value is string => {
  return typeof value === "string";
};
export const isArray = (value: unknown): value is unknown[] => {
  return Array.isArray(value);
};
export const isObject = (obj: unknown): obj is Record<string, unknown> => {
  return typeof obj === "object" && obj !== null;
};
export const isBoolean = (value: unknown): value is boolean => {
  return typeof value === "boolean";
};

export const classNames = (classObj: { [className: string]: boolean | undefined | null }) => {
  const classList: string[] = [];
  for (let className of Object.keys(classObj)) {
    if (classObj[className]) {
      classList.push(className);
    }
  }
  if (classList.length === 0) return undefined;
  return classList.join(" ");
};

export const isMacPlatform = () => {
  return navigator.platform.startsWith("Mac");
};

/**
 * Returns true if a UI element is focused and canvas interactions should be
 * disabled. For example, when copy-pasting in a text field or code editor.
 */
export const hasActiveElementFocus = () => {
  const { activeElement } = document;
  return (
    activeElement !== null && // According to MDN, activeElement can be null.
    activeElement !== document.body &&
    // Ignore focused checkboxes.
    !(activeElement instanceof HTMLInputElement && activeElement.type === "checkbox")
  );
};

export const isKeyboardEventForPlainTextField = (event: KeyboardEvent) => {
  if (isKeyboardEventForCodeMirror(event)) return false;
  return hasActiveElementFocus();
};

export const isKeyboardEventForCodeMirror = (event: KeyboardEvent) => {
  // Note: this implementation is very tied into CodeMirror's internal
  // implementation. If we update CodeMirror we may need to update this too!
  // -Toby
  if ((event as any).target?.parentNode?.parentNode?.classList?.contains("CodeMirror")) return true;
  return false;
};

export const isModifierKey = (key: string) => {
  if (key === "Shift") return true;
  if (key === "Control") return true;
  if (key === "Alt") return true;
  if (key === "Meta") return true;
  return false;
};

const distanceBetweenPointerEvents = (event1: PointerEvent, event2: PointerEvent) => {
  const x1 = event1.clientX;
  const y1 = event1.clientY;
  const x2 = event2.clientX;
  const y2 = event2.clientY;
  const dx = x2 - x1;
  const dy = y2 - y1;
  return Math.sqrt(dx * dx + dy * dy);
};

const doubleClickTimeThreshold = 400; // the time between two clicks must be less than this to count as a double click
const doubleClickDistanceThreshold = 4; // the distance between two clicks must be less than this to count as a double click

// TODO (toby): Haven't thought through edge cases with multiple pointers / touches.
let lastPointerDown: PointerEvent;
let lastPointerDownWasDoubleClick = false;

window.addEventListener("pointerdown", (downEvent) => {
  // Allows clicking on CodeMirror hints
  if (
    downEvent.target &&
    (downEvent.target as HTMLElement)?.classList?.contains("CodeMirror-hint")
  ) {
    downEvent.preventDefault();
  }
  lastPointerDownWasDoubleClick = isPointerEventDoubleClick(downEvent);
  lastPointerDown = downEvent;
});

export const isPointerEventDoubleClick = (downEvent: PointerEvent): boolean => {
  if (lastPointerDown === undefined || lastPointerDownWasDoubleClick) return false;
  const lastTimeStamp = lastPointerDown.timeStamp;
  const currentTimeStamp = downEvent.timeStamp;
  const isSoonEnough = currentTimeStamp - lastTimeStamp < doubleClickTimeThreshold;
  const isCloseEnough =
    distanceBetweenPointerEvents(lastPointerDown, downEvent) < doubleClickDistanceThreshold;
  return isSoonEnough && isCloseEnough;
};

export const isPointerEventRightClick = (event: PointerEvent) => {
  return event.button === 2;
};
export const isPointerEventMiddleClick = (event: PointerEvent) => {
  return event.button === 1;
};

export const domForVnode = (vnode: m.Vnode<any, any>) => {
  const el = (vnode as any).dom;
  if (!el) throw "vnode doesn't have .dom property";
  return el as HTMLElement;
};

/**
 * Must be called with a user action.
 * Ref: https://www.30secondsofcode.org/js/s/copy-to-clipboard
 */
export const copyToClipboard = (str: string) => {
  const el = document.createElement("textarea");
  el.value = str;
  el.setAttribute("readonly", "");
  el.style.position = "absolute";
  el.style.left = "-9999px";
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
};

/**
 * Vendored from https://github.com/KittyGiraudel/focusable-selectors/ on 2021-08-12
 */
export const focusableSelectors = [
  'a[href]:not([tabindex^="-"])',
  'area[href]:not([tabindex^="-"])',
  'input:not([type="hidden"]):not([type="radio"]):not([disabled]):not([tabindex^="-"])',
  'input[type="radio"]:not([disabled]):not([tabindex^="-"])',
  'select:not([disabled]):not([tabindex^="-"])',
  'textarea:not([disabled]):not([tabindex^="-"])',
  'button:not([disabled]):not([tabindex^="-"])',
  'iframe:not([tabindex^="-"])',
  'audio[controls]:not([tabindex^="-"])',
  'video[controls]:not([tabindex^="-"])',
  '[contenteditable]:not([tabindex^="-"])',
  '[tabindex]:not([tabindex^="-"])',
];

export const extensionFromPath = (path: string) => {
  return path.substring(path.lastIndexOf(".") + 1).toLowerCase();
};

// IMGIX can't operate on GIFs and SVGs, so we link to the unprocessed image
const IMGIX_PASSTHROUGH_EXTENSIONS = ["svg", "gif"];
export const isImagePathPassthrough = (path: string) => {
  const extension = extensionFromPath(path);
  return IMGIX_PASSTHROUGH_EXTENSIONS.includes(extension);
};

/** For 16:9 dashboard thumbnail urls */
const imgixThumbnailUrl = (s3Path: string, dpr: 1 | 2) => {
  // "Variable quality" reduces file size a bit for the high DPR source.
  // https://docs.imgix.com/tutorials/responsive-images-srcset-imgix#use-variable-quality
  // In our case, 1x is q 80, and 2x is q 60.
  const q = 40 + 40 / dpr;
  return `https://${publicBucketName}.imgix.net/${s3Path}?auto=compress,format&q=${q}&fit=fill&fill=blur&width=400&height=225&dpr=${dpr}`;
};

/** Image attributes for 16:9 dashboard thumbnail img */
export const imgixThumbnailAttrs = (s3Path: string) => {
  if (isImagePathPassthrough(s3Path)) {
    const src = `https://${publicBucketName}.s3.us-west-1.amazonaws.com/${s3Path}`;
    return { src, srcset: `${src} 1x` };
  }
  const standardSize = imgixThumbnailUrl(s3Path, 1);
  const retinaSize = imgixThumbnailUrl(s3Path, 2);
  return { src: standardSize, srcset: `${standardSize} 1x, ${retinaSize} 2x` };
};

/**
 * Returns a function, that, as long as it continues to be invoked, will not be
 * triggered. The function will be called after it stops being called for
 * `afterInterval` milliseconds.
 */
export const debounce = (functionToCall: () => void, afterInterval: number) => {
  let timeout: number | undefined;
  const onTimeout = () => {
    clearTimeout(timeout);
    functionToCall();
  };
  return () => {
    window.clearTimeout(timeout);
    timeout = window.setTimeout(onTimeout, afterInterval);
  };
};

/**
 * Replaces any run of characters that are not a letter, number, or underscore
 * with "-" and trims any "-" from the front and back.
 */
const encodeTitleForUrl = (title: string) => {
  return title.replace(/[^\w]+/g, "-").replace(/(^-)|(-$)/g, "");
};

/** For example, "/@owner/Project-Title-DLiTj2WfI7Xu" */
export const canonicalUrlForProject = (owner: string, title: string, projectId: string) => {
  if (projectId === "_intro") return "/intro";
  return `/@${owner}/${encodeTitleForUrl(title)}-${projectId}`;
};

// prettier-ignore
const reProjectUrl = SuperExpressive()
  .startOfInput
  .string("/@")
  .namedCapture("ProjectOwner")
    .oneOrMore.word
  .end()
  .string("/")
  .namedCapture("ProjectTitle")
    .oneOrMore.anyOf
      .word
      .string("-")
    .end()
  .end()
  .string("-")
  .namedCapture("ProjectId")
    .oneOrMore.anyOf
      .range("a", "z")
      .range("A", "Z")
      .range("0", "9")
    .end()
  .end()
  .endOfInput
  .toRegex();

export const parseProjectUrl = (s: string) => {
  const result = reProjectUrl.exec(s);
  if (!result || !result.groups) return undefined;

  return {
    owner: result.groups.ProjectOwner,
    title: result.groups.ProjectTitle,
    projectId: result.groups.ProjectId,
  };
};

export const canonicalUrlForForked = (forkedFrom: {
  owner: string;
  name: string;
  projectId: string;
}) => {
  return canonicalUrlForProject(forkedFrom.owner, forkedFrom.name, forkedFrom.projectId);
};

// We'll save a 800x450 transparent png if a preview png is undefined (e.g. because
// there's no geometry on the focused component).
const transparentPngBase64 =
  "iVBORw0KGgoAAAANSUhEUgAAAyAAAAHCAQMAAAAtrT+LAAAAA1BMVEUAAACnej3aAAAAAXRSTlMAQObYZgAAAENJREFUeNrtwYEAAAAAw6D7U19hANUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAALIDsYoAAZ9qTLEAAAAASUVORK5CYII=";
export const transparentPngBlob = base64StringToBlob(transparentPngBase64, "image/png");

export const transparentPngUrl = URL.createObjectURL(transparentPngBlob);

export const goToDashboard = () => {
  m.route.set("/dashboard");
};

export const formatDate = (date: Date | string) => {
  const now = dayjs();
  const d = dayjs.utc(date).local();
  if (d.year() === now.year()) {
    // Don't need to display the year
    return d.format("MMM D");
  } else {
    return d.format("MMM D, YYYY");
  }
};

export const formatDateFullMonth = (date: Date | string) => {
  const now = dayjs();
  const d = dayjs.utc(date).local();
  if (d.year() === now.year()) {
    // Don't need to display the year
    return d.format("MMMM D");
  } else {
    return d.format("MMMM D, YYYY");
  }
};

export const formatDateAndTime = (date: Date | string) => {
  const d = dayjs.utc(date).local();
  return d.format("YYYY-MM-DD, hh:mm A");
};

export async function hashSHA256(arrayBuffer: ArrayBuffer): Promise<string> {
  const hashBuffer = await crypto.subtle.digest("SHA-256", arrayBuffer); // hash the file
  const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert hash buffer to byte array
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, "0")).join(""); // convert bytes to hex string
  return hashHex;
}
