import m from "mithril";

import { DashboardDragOverlay } from "./dashboard-drag-overlay";
import { Tile } from "./dashboard-project-tile";
import { DashboardSidebar } from "./dashboard-sidebar";
import { IntroTile } from "./dashboard-tutorials";
import { LandingTemplates } from "./landing-page/landing-templates";
import { accountState } from "./shared/account";
import type { ProjectCardData } from "./shared/api-types";
import { Icon20 } from "./shared/icon";
import { logEvent } from "./shared/log-event";
import { SiteLayout } from "./shared/site-layout/site-layout";
import { openUpgradeModal } from "./shared/upgrade-modal";
import { dashboardState } from "./dashboard-state";
import { enforceLogin, updateHead } from "./util";
import { DashboardTag } from "./tags";
import { TagStrip } from "./tag-strip";

export type DashboardSelectedPage =
  | { page: "home" }
  | { page: "folder"; folderId: string }
  | { page: "template"; tagSlug: string };

interface DashboardPageAttrs {
  selectedPage: DashboardSelectedPage;
}
export const DashboardPage: m.Component<DashboardPageAttrs> = {
  view({ attrs: { selectedPage } }) {
    let mArticle;
    if (selectedPage.page === "folder") {
      const folderId = selectedPage.folderId;
      mArticle = m(DashboardFolder, { folderId });
    } else if (selectedPage.page === "template") {
      const tagSlug = selectedPage.tagSlug;
      mArticle = m(DashboardTag, { tagSlug });
    } else {
      mArticle = m(DashboardHome);
    }

    const mDashboardBody = m(".with-sidebar", [
      //
      m(DashboardSidebar, { selectedPage }),
      mArticle,
    ]);

    const hamburgerExtra = () => {
      return m(DashboardSidebar, { selectedPage });
    };

    return m(
      SiteLayout,
      {
        displayAboveNav: m(DashboardDisplayAboveNav),
        hamburgerExtra,
      },
      [
        //
        m(DashboardDragOverlay),
        m(".main-inner.full-width", [
          //
          m(DashboardProjectsRemainingMessage),
          mDashboardBody,
        ]),
      ]
    );
  },
};

interface DashboardNewProjectTileAttrs {
  folderId: string;
}
const DashboardNewProjectTile: m.Component<DashboardNewProjectTileAttrs> = {
  view({ attrs: { folderId } }) {
    const loggedInUser = accountState.loggedInUser;
    if (!loggedInUser) return;

    const mAddProjectTile = m(".tile-container.add-project", [
      m(".plus-and-text", [m(Icon20, { icon: "plus_new_project" }), "New Blank Project"]),
    ]);

    const subscription = loggedInUser.subscription;

    if (subscription.plan === "Free" && subscription.projectsRemaining === 0) {
      return m("div", { onclick: () => openUpgradeModal("projects") }, [mAddProjectTile]);
    } else {
      return m(
        "a",
        {
          href: `/new-project/?folder=${folderId}`,
          target: "_blank",
        },
        [mAddProjectTile]
      );
    }
  },
};

const DashboardHome: m.Component<{}> = {
  view() {
    if (!accountState.loggedInUser) {
      return enforceLogin();
    }
    updateHead({
      title: `Cuttle: Home`,
    });
    if (!dashboardState.hasLoadedProjects) {
      // dashboard is still loading
      // TODO: Loading indicator
      return m("div");
    }

    const projects = dashboardState.projects;

    const mTiles: m.Children = [];

    mTiles.push(m(DashboardNewProjectTile, { folderId: dashboardState.firstFolderId() || "" }));

    const numProjectsToShow = 11;
    const projectsToShow = projects.slice().sort(sortByLastOpenedDate).slice(0, numProjectsToShow);
    mTiles.push(
      projectsToShow.map((project) => {
        return m(Tile, { project, key: project.projectId });
      })
    );
    // Show intro card if there's room.
    if (projectsToShow.length < numProjectsToShow) {
      mTiles.push(m(IntroTile));
    }

    return m("div", [
      m(".dashboard-section", [
        m(".dashboard-section-header", m("h2", "My Recent Projects")),
        m(".tiles-container", mTiles),
      ]),
      m(DashboardTemplates),
    ]);
  },
};

interface DashboardFolderAttrs {
  folderId: string;
}
const DashboardFolder: m.Component<DashboardFolderAttrs> = {
  view({ attrs: { folderId } }) {
    if (!accountState.loggedInUser) {
      return enforceLogin();
    }
    if (!dashboardState.hasLoadedProjects) {
      // dashboard is still loading
      // TODO: Loading indicator
      return m("div");
    }
    const selectedFolder = dashboardState.folderById(folderId);
    if (!selectedFolder) {
      m.route.set("/dashboard", {}, { replace: true });
      return;
    }

    updateHead({
      title: `${selectedFolder.name} - Cuttle`,
    });

    const projectsInSelectedFolder = dashboardState.projectsInFolder(folderId);

    const mTiles: m.Children = [];

    mTiles.push(m(DashboardNewProjectTile, { folderId }));

    mTiles.push(
      projectsInSelectedFolder.map((project) => {
        return m(Tile, { project, key: project.projectId });
      })
    );

    return m("div", [m(".dashboard-section", [m(".tiles-container", mTiles)])]);
  },
};

const DashboardDisplayAboveNav: m.Component<{}> = {
  view() {
    const displayAboveNav: m.ChildArray = [];

    if (false) {
      // Maintenance
      displayAboveNav.push(
        m(".top-banner-wrapper", [
          m("p", [
            // "On ",
            m("strong", "Today (July 13th) at 6pm PT"),
            " we're performing an infrastructure upgrade on Cuttle. We anticipate 5-10 minutes of down time.",
          ]),
        ])
      );
    }

    return displayAboveNav;
  },
};

const DashboardProjectsRemainingMessage: m.Component<{}> = {
  view() {
    if (!accountState.loggedInUser) {
      return null;
    }
    const subscription = accountState.loggedInUser.subscription;
    const numProjects = dashboardState.projects.length;

    if (subscription.plan === "Free" && numProjects > 0) {
      let projectOrProjects = "projects";
      if (subscription.projectsRemaining === 1) {
        projectOrProjects = "project";
      }
      return m(".dashboard-message-box", [
        m(".dashboard-subscription-message", [
          m("p", [
            "You are currently on the ",
            m("strong", "Free"),
            " plan. You have ",
            m("strong", subscription.projectsRemaining),
            ` free ${projectOrProjects} remaining.`,
          ]),
          m(
            m.route.Link,
            {
              className: "dashboard-upgrade-button",
              role: "button",
              href: "/pricing",
              onclick: () => logEvent("clicked pricing", { from: "projects remaining" }),
            },
            "Upgrade to Pro"
          ),
        ]),
      ]);
    }
  },
};

const DashboardTemplates: m.Component<{}> = {
  view() {
    return m(".dashboard-section", [
      m(".dashboard-section-header", [
        m("h2", "Templates"),
        m(
          m.route.Link,
          { class: "dashboard-section-link", href: "/templates" },
          "Browse all Templates →"
        ),
      ]),
      m(TagStrip),
      m(LandingTemplates),
      m(
        m.route.Link,
        { class: "dashboard-browse-link", href: "/templates" },
        "Browse all Templates →"
      ),
    ]);
  },
};

function sortByLastOpenedDate(a: ProjectCardData, b: ProjectCardData) {
  return Date.parse(b.lastOpenedDate) - Date.parse(a.lastOpenedDate);
}
