import m from "mithril";
import { accountState } from "./shared/account.js";
import { enforceLogin, updateHead } from "./util.js";

export const StatsPage: m.Component<{}> = {
  view() {
    if (!accountState.loggedInUser) {
      return enforceLogin();
    }
    if (!accountState.loggedInUser.isAdmin) {
      return;
    }

    updateHead({
      title: "Cuttle - Stats",
    });
    return m(".stats-main", [
      m(".stats-main-links", [
        m("p", m(m.route.Link, { href: "/stats/subscriptions" }, "💖 Subscriptions 💖")),
        m("p", m(m.route.Link, { href: "/stats/cancellations" }, "😿 Cancellations 😿")),
        m("p", m(m.route.Link, { href: "/stats/leaderboard" }, "🏆 Leaderboard 🏆")),
      ]),
    ]);
  },
};
